import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as yup from 'yup';

import { PmLegalTermsFooter } from 'containers/shared/footers/pm_legal_terms_footer';
import { UnauthenticatedHeader } from 'containers/shared/headers/unauthenticated_header';
import { PageNames, UnauthenticatedTitles } from 'containers/shared/headers/unauthenticated_titles';
import { MarketingConsentStatus, PlanFamily } from 'daos/enums';
import { OrganizationUser } from 'daos/model_types';
import { RegistrationDao } from 'daos/registration';
import { setCurrentUserId } from 'features/common/current/slice';
import { getMarketingTags } from 'features/common/marketing_tags';
import { awaitRequestFinish } from 'lib/api';
import { OriginPage, avoClient } from 'lib/avo/client';
import { MIN_PASSWORD_LENGTH } from 'lib/constants';
import {
  AnalyticsRegistrationEvent,
  logGoogleAnalyticsRegistrationEvent,
} from 'lib/google_analytics/log_google_analytics_event';
import { useYupEmailValidator } from 'lib/helpers/yup/lp_email_validation';
import { lpErrorText } from 'lib/helpers/yup/lp_error_text';
import { currentBrowserLocale, currentBrowserTimezone } from 'lib/localization';
import { defaultLandingPage } from 'lib/urls';

import Step1 from './step1';
import Step2 from './step2';

import 'features/authentication/unauthenticated/registration_v2/index.scss';

const { segmentKey } = window as any;

const FORM_STEPS_TITLES = {
  1: {
    TITLE: 'Control project risk and deliver on time',
    SUBTITLE: 'Fields marked with * are mandatory.',
  },
  2: {
    TITLE: 'Tell us about yourself',
    SUBTITLE: "We'll customize your experience based on your choice.",
  },
};

const phoneNumberFormattingCharacters = /[-_()., +]/g;
const phoneNumberValidPattern = /^\d{10,14}$/;

const SIGNUP_KEY_LABEL = 'Signup Key';
const requireSignupKey = window.relightRequireSignupKey === 'true';

export const RegistrationV2 = (props: any) => {
  const { frontloadData } = props;
  const [currentPageNum, setCurrentPageNum] = useState<keyof typeof FORM_STEPS_TITLES>(1);
  const pageName = currentPageNum === 1 ? PageNames.SIGNUP : PageNames.SIGNUP_STEP_2;
  const dispatch = useDispatch();
  const history = useHistory();
  const [avoSignUpStarted, setAvoSignUpStarted] = useState(false);

  const { location } = history;
  const marketingTags = getMarketingTags();

  useEffect(() => {
    logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationBegan);

    try {
      localStorage.clear();
      // eslint-disable-next-line no-empty
    } catch (_) {
      // empty catch to handle users who block localStorage use on their browsers
    }
  }, []);

  const handleDismissApiError = () => step2Form.setStatus(undefined);

  const step1FormValidationSchema = yup
    .object()
    .shape({
      email: useYupEmailValidator(),
      password: yup.string().min(MIN_PASSWORD_LENGTH, lpErrorText.passwordLength).required(lpErrorText.passwordLength),
      passwordConfirmation: yup.string().required(lpErrorText.passwordConfirmation),
    })
    .test('passwords-do-not-match', function ({ password, passwordConfirmation }) {
      if (password !== passwordConfirmation) {
        return this.createError({
          message: lpErrorText.passwordMatch,
          path: 'passwordConfirmation',
        });
      }

      return true;
    });

  const step1Form = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: step1FormValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: () => {
      step1Form.setSubmitting(false);
      setCurrentPageNum(2);
    },
  });

  const step2FormValidationSchema = yup.object().shape({
    firstName: yup.string().trim().required(lpErrorText.firstName),
    lastName: yup.string().trim().required(lpErrorText.lastName),
    phoneNumber: yup
      .string()
      .trim()
      .transform((value) => value.replace(phoneNumberFormattingCharacters, ''))
      .required(lpErrorText.phoneNumberRequired)
      .matches(phoneNumberValidPattern, lpErrorText.phoneNumber),
    companyName: yup
      .string()
      .trim()
      .required(lpErrorText.companyName)
      .matches(/^[a-zA-Z0-9\s-_]*$/, lpErrorText.companyNameInvalid),
    requireSignupKey: yup.boolean(),
    signupKey: yup
      .string()
      .label(SIGNUP_KEY_LABEL)
      .when('requireSignupKey', {
        is: true,
        then: (schema) => schema.required(lpErrorText.signUpKey),
        otherwise: (schema) => schema.notRequired(),
      }),
    jobTitle: yup.string().trim().required(lpErrorText.jobTitle),
    companySize: yup.string().trim().required(lpErrorText.companySize),
  });

  const step2Form = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      passwordConfirmation: '',
      phoneNumber: '',
      companyName: '',
      requireSignupKey,
      signupKey: '',
      tosCheckbox: false,
      jobTitle: '',
      companySize: '',
    },
    validationSchema: step2FormValidationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({ firstName, lastName, companyName, phoneNumber, jobTitle, companySize, signupKey }) => {
      step1Form.setStatus(undefined);
      step2Form.setStatus(undefined);
      const { uuid } = dispatch(
        RegistrationDao.registerAccount({
          email: step1Form.values.email,
          firstName,
          lastName,
          password: step1Form.values.password,
          phoneNumber,
          organizationName: companyName,
          companySize,
          jobTitle,
          signupKey,
          organizationProjectTypes: [],
          planFamily: PlanFamily.UltimateTrial,
          creator: {
            locale: currentBrowserLocale(),
            timezone: currentBrowserTimezone(),
          },
          marketingConsent: MarketingConsentStatus['Opt-In'],
          tosAccepted: true,
        })
      );

      dispatch(
        awaitRequestFinish<OrganizationUser>(uuid, {
          onError: ({ errors }) => {
            if (errors[0]) {
              step2Form.setStatus(errors[0]);
            }

            step2Form.setSubmitting(false);
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationFailed);
          },
          onSuccess: ({ data }) => {
            dispatch(setCurrentUserId(data.user.id));

            frontloadData({
              providedOrgId: data.organization.id,
              providedWsId: undefined,
              callbackOnFrontloadDataComplete: (workspaceId: string) => {
                history.push(defaultLandingPage.url({ organizationId: data.organization.id, workspaceId }));
              },
            });
            if (segmentKey) {
              avoClient?.signUpCompleted({
                originPage: OriginPage.TRIAL_SIGN_UP,
                url: location.pathname,
                userFirstName: firstName,
                userLastName: lastName,
                lpCompanyName: companyName,
                utmSource: marketingTags.utm_source ?? '',
                utmMedium: marketingTags.utm_medium ?? '',
                utmCampaign: marketingTags.utm_campaign ?? '',
                utmTerm: marketingTags.utm_term ?? '',
                utmContent: marketingTags.utm_content ?? '',
                email: step1Form.values.email ?? '',
              });
            }
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationCompletedUserData);
            logGoogleAnalyticsRegistrationEvent(AnalyticsRegistrationEvent.RegistrationCompleted);
          },
        })
      );
    },
  });

  useEffect(() => {
    if (!avoSignUpStarted && Object.values({ ...step1Form.values, ...step2Form.values }).some((value) => !!value)) {
      if (segmentKey) {
        avoClient?.signUpStarted({
          originPage: OriginPage.TRIAL_SIGN_UP,
          url: location.pathname,
        });
      }
      setAvoSignUpStarted(true);
    }
  }, [step1Form.values, step2Form.values, avoSignUpStarted, location.pathname]);

  return (
    <div className="pm-signup">
      <UnauthenticatedHeader className="pm-signup__header" currentPageNum={currentPageNum} />
      <div className="pm-signup__steps">
        {currentPageNum === 2 && (
          <div className="steps__back-action">
            <p onClick={() => setCurrentPageNum(1)}>← Back</p>
          </div>
        )}
        <div className="steps__content">
          <UnauthenticatedTitles className="steps__titles" pageName={pageName} />
          {currentPageNum === 1 ? (
            <Step1 {...step1Form} />
          ) : (
            <Step2 {...step2Form} handleDismissApiError={handleDismissApiError} />
          )}
        </div>
      </div>
      <PmLegalTermsFooter />
    </div>
  );
};
