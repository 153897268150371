import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { GroupType } from 'daos/enums';
import { Group } from 'daos/model_types';
import { getCurrentWorkspaceId } from 'features/common/current/selectors';
import { readonlyArray, ReadonlyRecord, groupRecordBy } from 'lib/readonly_record';
import { RootState } from 'redux/root_reducer';

export const getGroupsById = ({ entities }: RootState): ReadonlyRecord<string, Group> => entities.groups;

export const getGroupForId = (state: RootState, id: number | string) => getGroupsById(state)[id];

const getGroupsByWorkspaceId = createSelector(getGroupsById, (groups) =>
  groupRecordBy(groups, (group) => group.workspace.id)
);

export const getCurrentWorkspaceGroups = createSelector(
  getGroupsByWorkspaceId,
  getCurrentWorkspaceId,
  (groupsByWsId, wsId) => groupsByWsId[wsId]
);

const getCurrentWorkspaceAdminGroups = createSelector(getCurrentWorkspaceGroups, (allWorkspaceGroups) => {
  return allWorkspaceGroups?.filter((wsGroup) => wsGroup.groupType === GroupType.Administrative) ?? [];
});

const getCurrentWorkspaceWorkTeams = createSelector(getCurrentWorkspaceGroups, (allWorkspaceGroups) => {
  return allWorkspaceGroups?.filter((wsGroup) => wsGroup.groupType === GroupType.Work) ?? [];
});

export const getCurrentWorkspaceGroupsSortedByName = createSelector(getCurrentWorkspaceAdminGroups, (groups) =>
  readonlyArray(sortBy(groups, (group) => (group.name ?? '').toLowerCase()))
);

export const getCurrentWorkspaceWorkTeamsSortedByName = createSelector(getCurrentWorkspaceWorkTeams, (groups) =>
  readonlyArray(sortBy(groups, (group) => (group.name ?? '').toLowerCase()))
);
