import { ReadonlyRecord } from 'lib/readonly_record';

export enum AccessType {
  Explicit = 'explicit',
  Implicit = 'implicit',
  None = 'none',
}

export enum AvailabilityType {
  AvailabilityRule = 'availabilityRule',
  PersonalTimeOff = 'personalTimeOff',
  EveryoneTimeOff = 'everyoneTimeOff',
}

export enum BillingPeriod {
  Annual = 'annual',
  Semiannual = 'semiannual',
  Quarterly = 'quarterly',
  Monthly = 'monthly',
  Other = 'other',
}
export const BillingPeriodDisplay = {
  [BillingPeriod.Annual]: 'annually',
  [BillingPeriod.Semiannual]: 'semiannually',
  [BillingPeriod.Quarterly]: 'quarterly',
  [BillingPeriod.Monthly]: 'monthly',
  [BillingPeriod.Other]: 'other',
};

export enum PlanFamily {
  Free = 'free',
  UltimateTrial = 'ultimateTrial',
  Essentials = 'essentials',
  Professional = 'professional',
  Ultimate = 'ultimate',
  Other = 'other',
}

export const planFamilyDisplayText: Readonly<Record<PlanFamily, string>> = {
  [PlanFamily.Free]: 'FREE PLAN',
  [PlanFamily.UltimateTrial]: 'ULTIMATE TRIAL',
  [PlanFamily.Essentials]: 'ESSENTIALS',
  [PlanFamily.Professional]: 'PROFESSIONAL',
  [PlanFamily.Ultimate]: 'ULTIMATE',
  [PlanFamily.Other]: 'OTHER',
};

export const planFamilyDisplayTextWithPlan: Readonly<Record<PlanFamily, string>> = {
  [PlanFamily.Free]: 'FREE PLAN',
  [PlanFamily.UltimateTrial]: 'ULTIMATE TRIAL',
  [PlanFamily.Essentials]: 'ESSENTIALS PLAN',
  [PlanFamily.Professional]: 'PROFESSIONAL PLAN',
  [PlanFamily.Ultimate]: 'ULTIMATE PLAN',
  [PlanFamily.Other]: 'OTHER PLAN',
};

export enum PricingModel {
  FlatFee = 'FLAT_FEE',
  PerUnit = 'PER_UNIT',
  Tiered = 'TIERED',
  Volume = 'VOLUME',
  Stairstep = 'STAIRSTEP',
}

export enum ChangeActionType {
  INSERT = 'insert',
  DELETE = 'delete',
  UPDATE = 'update',
}

export enum ChangeModelType {
  Dependencies = 'dependencies',
  FieldValues = 'fieldValues',
  Files = 'files',
  Groups = 'groups',
  Items = 'items',
  ItemAcls = 'itemAcls',
  TimesheetEntries = 'timesheetEntries',
  SchedulingLimits = 'schedulingLimits',
  ItemFiles = 'itemFiles',
}

export enum ChangeOrigin {
  IntakeWidget = 'intakeWidget',
  WebUi = 'webUi',
  PublicApi = 'publicApi',
}

export enum CustomFieldType {
  CHECKBOX = 'checkbox',
  CURRENCY = 'currency',
  DATE = 'date',
  MULTILINE_TEXT = 'multilineText',
  ITEM = 'item',
  LINK = 'link',
  MULTI_ITEM = 'multiItem',
  MULTI_LINK = 'multiLink',
  MULTI_PICKLIST = 'multiPicklist',
  MULTI_TEXT = 'multiText',
  MULTI_USER = 'multiUser',
  NOTE = 'note',
  NUMERIC = 'numeric',
  PICKLIST = 'picklist',
  TEXT = 'text',
  USER = 'user',
}

export enum CustomFieldTypeDisplay {
  checkbox = 'Checkbox',
  currency = 'Currency',
  date = 'Date',
  multilineText = 'Multi-line Text',
  item = 'Item',
  link = 'Link',
  multiItem = 'Item (multi)',
  multiLink = 'Link (multi)',
  multiPicklist = 'Picklist (multi)',
  multiText = 'Text (multi)',
  multiUser = 'User (multi)',
  note = 'Note',
  numeric = 'Numeric',
  picklist = 'Picklist',
  text = 'Text',
  user = 'User',
}

export enum DashboardOrderBy {
  DashboardName = 'name',
  ItemName = 'item.name',
  WorkspaceDashboardName = 'name,id',
}

export enum DeletedItemOrder {
  DeletedAtDescending = 'deletedAt desc',
  GlobalPriorityAscending = 'globalPriority asc',
}

export enum DependencyStatus {
  ACTIVE = 'active',
  CIRCULAR = 'circular',
  CLIPPED = 'clipped',
  SATISFIED = 'satisfied',
  UNSCHEDULED = 'unscheduled',
}

export enum EmailFrequency {
  NONE = 'none',
  DAILY = 'daily',
  HOURLY = 'hourly',
  QUARTER_HOUR = 'quarter_hour',
}

export const EmailFrequencyDisplay = {
  [EmailFrequency.NONE]: 'Never',
  [EmailFrequency.DAILY]: 'Once Daily',
  [EmailFrequency.HOURLY]: 'Once every hour',
  [EmailFrequency.QUARTER_HOUR]: 'Once every 15 minutes',
};

export enum FavoriteViewType {
  Group = 'group',
  GroupAccess = 'groupAccess',
  GroupBoard = 'groupBoard',
  GroupTasks = 'groupTasks',
  Groups = 'groups',
  MemberAccess = 'memberAccess',
  MemberAvailability = 'memberAvailability',
  MemberBoard = 'memberBoard',
  MemberChanges = 'memberChanges',
  MemberProfile = 'memberProfile',
  MemberSearch = 'memberSearch',
  MemberTasks = 'memberTasks',
  MemberTimesheet = 'memberTimesheet',
  MembersAvailability = 'membersAvailability',
  MyAccess = 'myAccess',
  MyAvailability = 'myAvailability',
  MyBoard = 'myBoard',
  MyChanges = 'myChanges',
  MyProfile = 'myProfile',
  MySearch = 'mySearch',
  MyTimesheet = 'myTimesheet',
  MyWorkload = 'myWorkload',
  LibraryResource = 'libraryResource',
  Package = 'pkg',
  PackageAllocation = 'pkgAllocation',
  PackageBoard = 'pkgBoard',
  PackageChanges = 'pkgChanges',
  PackageGrid = 'pkgGrid',
  PackageLibrary = 'pkgLibrary',
  PackageSearch = 'pkgSearch',
  PackageWorkload = 'pkgWorkload',
  PortfolioAll = 'portfolioAll',
  PortfolioArchived = 'portfolioArchived',
  PortfolioArchivedChanges = 'portfolioArchivedChanges',
  PortfolioArchivedGrid = 'portfolioArchivedGrid',
  PortfolioArchivedLibrary = 'portfolioArchivedLibrary',
  PortfolioArchivedSearch = 'portfolioArchivedSearch',
  PortfolioPending = 'portfolioPending',
  PortfolioPendingChanges = 'portfolioPendingChanges',
  PortfolioPendingGrid = 'portfolioPendingGrid',
  PortfolioPendingLibrary = 'portfolioPendingLibrary',
  PortfolioPendingSearch = 'portfolioPendingSearch',
  PortfolioScheduled = 'portfolioScheduled',
  PortfolioScheduledChanges = 'portfolioScheduledChanges',
  PortfolioScheduledGrid = 'portfolioScheduledGrid',
  PortfolioScheduledLibrary = 'portfolioScheduledLibrary',
  PortfolioScheduledSearch = 'portfolioScheduledSearch',
  PortfolioScheduledWorkload = 'portfolioScheduledWorkload',
  PortfolioCapacity = 'portfolioCapacity',
  PortfolioCapacityAllocation = 'portfolioCapacityAllocation',
  Profiles = 'profiles',
  Project = 'project',
  ProjectBoard = 'projectBoard',
  ProjectChanges = 'projectChanges',
  ProjectGrid = 'projectGrid',
  ProjectLibrary = 'projectLibrary',
  ProjectSearch = 'projectSearch',
  ProjectAllocation = 'projectAllocation',
  ProjectWorkload = 'projectWorkload',
  TimesheetExport = 'timesheetExport',
  TimesheetReview = 'timesheetReview',
}

export enum FilterStatusType {
  INUSE = 'inUse',
  ARCHIVED = 'archived',
  ALL = 'all',
}

export enum RateSheetFilterType {
  InUse = 'inUse',
  Archived = 'archived',
  AllRateSheets = 'allRateSheets',
  ActiveBillingRate = 'activeBillingRate',
  ActivePayRate = 'activePayRate',
}

// TODO: Reconcile this with FolderFilterStatus enum.
export enum FolderStatusFilter {
  All = 'all',
  ActiveOnHold = 'activeAndOnHold',
  Done = 'done',
  OnHold = 'onHold',
  Scheduled = 'active',
}

export enum GroupType {
  Administrative = 'administrativeGroup',
  Work = 'workGroup',
}

export enum InvitationState {
  Accepted = 'accepted',
  Expired = 'expired',
  Sent = 'sent',
  Revoked = 'revoked',
}

export enum ItemType {
  ASSIGNMENTS = 'assignments',
  FOLDERS = 'folders',
  PACKAGES = 'packages',
  PROJECTS = 'projects',
  TASKS = 'tasks',
  WORKSPACE_ROOTS = 'workspaceRoots',
}

export enum ListWidgetType {
  Assignment = 'assignment',
  Folders = 'folders',
  Packages = 'packages',
  Picklists = 'picklists',
  Projects = 'projects',
  Tasks = 'tasks',
  Users = 'users',
}

export enum LibraryResourceOrderBy {
  Name = 'name',
}

export enum ManageAccountPlanChangeType {
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
}

export enum OrganizationProjectTypeType {
  PersonalUse = 'PersonalUse',
  EducationalUse = 'EducationalUse',
  ProfessionalServices = 'ProfessionalServices',
  ProductDevelopment = 'ProductDevelopment',
  InformationTechnology = 'InformationTechnology',
  EngineeringHardware = 'EngineeringHardware',
  EngineeringSoftware = 'EngineeringSoftware',
  MarketingAndCreative = 'MarketingAndCreative',
  ResearchAndDevelopment = 'ResearchAndDevelopment',
  Manufacturing = 'Manufacturing',
  HumanResources = 'HumanResources',
  Other = 'Other',
}

export enum OrganizationUserOrderBy {
  Username = 'username',
}

export enum PackageStatus {
  ARCHIVED = 'archived',
  BACKLOG = 'backlog',
  SCHEDULED = 'scheduled',
  TEMPLATE = 'template',
  CAPACITY = 'capacity',
}

export enum Permission {
  NONE = 'none',
  OBSERVE = 'observe',
  EDIT = 'edit',
  MANAGE = 'manage',
  ADMIN = 'admin',
}

export enum PropertyChangeHistoryAction {
  New = 'new',
  Old = 'old',
}

export enum PropertyChangeHistoryProperties {
  AccessLevel = 'Access Level',
  AssignmentsSchedule = 'Assignments Schedule',
  AtFinish = 'At Finish...',
  BenchmarkEstimate = 'Benchmark Estimate',
  Checked = 'Checked',
  Choice = 'Choice',
  Color = 'Color',
  CostCode = 'Cost Code',
  Deadline = 'Deadline',
  Deleted = 'Deleted',
  Description = 'Description',
  Done = 'Done',
  File = 'File',
  Group = 'Group',
  HighEstimate = 'High Estimate',
  Icon = 'Icon',
  Item = 'Item',
  Iteration = 'Iteration',
  Limit = 'Limit',
  LoggedTime = 'Logged Time',
  LowEstimate = 'Low Estimate',
  Name = 'Name',
  Parent = 'Parent',
  Predecessor = 'Predecessor',
  PriorityRush = 'Priority Rush',
  Reprioritized = 'Reprioritized',
  BillingRateSheet = 'Billing Rate Sheet',
  PayRateSheet = 'Pay Rate Sheet',
  Status = 'Status',
  StoryPoints = 'Story points',
  TargetFinish = 'Target Finish',
  TargetStart = 'Target Start',
  Text = 'Text',
  Type = 'Type',
  URL = 'URL',
  User = 'User',
  WorkLimit = 'Work Limit',
}

export enum RateSheetType {
  BillingRate = 'billingRate',
  PayRate = 'payRate',
}

// Enum values are in this order for UI display purposes
export enum ScheduleDirective {
  TRACKING_ONLY = 'trackingOnly',
  NORMAL = 'normal',
  ASAP_IN_PROJECT = 'asapInProject',
  ASAP_IN_PACKAGE = 'asapInPackage',
  ASAP_IN_WORKSPACE = 'asapInWorkspace',
}

enum ScheduleDirectiveInItemTypeDisplay {
  TrackingOnly = 'Tracking Only',
  AsPrioritized = 'as Prioritized',
  WithinPackage = 'within Package',
  WithinProject = 'within Project',
  WithinWorkspace = 'within Workspace',
}

export const ScheduleDirectiveToScheduleDirectiveDisplayTextWithIcon: ReadonlyRecord<
  ScheduleDirective,
  ScheduleDirectiveInItemTypeDisplay
> = {
  [ScheduleDirective.TRACKING_ONLY]: ScheduleDirectiveInItemTypeDisplay.TrackingOnly,
  [ScheduleDirective.NORMAL]: ScheduleDirectiveInItemTypeDisplay.AsPrioritized,
  [ScheduleDirective.ASAP_IN_PACKAGE]: ScheduleDirectiveInItemTypeDisplay.WithinPackage,
  [ScheduleDirective.ASAP_IN_PROJECT]: ScheduleDirectiveInItemTypeDisplay.WithinProject,
  [ScheduleDirective.ASAP_IN_WORKSPACE]: ScheduleDirectiveInItemTypeDisplay.WithinWorkspace,
};

enum ScheduleDirectiveDisplay {
  TRACKING_ONLY = 'Tracking Only',
  NORMAL = 'As Prioritized',
  ASAP_IN_PACKAGE = 'ASAP within Package',
  ASAP_IN_PROJECT = 'ASAP within Project',
  ASAP_IN_WORKSPACE = 'ASAP within Workspace',
}

export const ScheduleDirectiveToScheduleDirectiveDisplayText: ReadonlyRecord<
  ScheduleDirective,
  ScheduleDirectiveDisplay
> = {
  [ScheduleDirective.TRACKING_ONLY]: ScheduleDirectiveDisplay.TRACKING_ONLY,
  [ScheduleDirective.NORMAL]: ScheduleDirectiveDisplay.NORMAL,
  [ScheduleDirective.ASAP_IN_PACKAGE]: ScheduleDirectiveDisplay.ASAP_IN_PACKAGE,
  [ScheduleDirective.ASAP_IN_PROJECT]: ScheduleDirectiveDisplay.ASAP_IN_PROJECT,
  [ScheduleDirective.ASAP_IN_WORKSPACE]: ScheduleDirectiveDisplay.ASAP_IN_WORKSPACE,
};

export enum SchedulingLimitType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum SchedulingType {
  Done = 'done',
  Scheduled = 'scheduled',
  Unscheduled = 'unscheduled',
}

export enum StatusFilterGroups {
  All = 'all',
  ActiveAndOnHold = 'activeAndOnHold',
  Done = 'done',
  OnHold = 'onHold',
  Active = 'active',
  atRisk = 'atRisk',
  Asap = 'asap',
  Custom = 'custom',
  None = 'none',
}
export enum SubscriptionStatus {
  Future = 'FUTURE',
  InTrial = 'IN_TRIAL',
  Active = 'ACTIVE',
  NonRenewing = 'NON_RENEWING',
  Paused = 'PAUSED',
  Cancelled = 'CANCELLED',
}

export enum TaskStatusDropdownFilter {
  ALL = 'All Tasks',
  ASAP = 'ASAP Tasks',
  SCHEDULED = 'Active Tasks',
  ACTIVE = 'Active + On Hold',
  ON_HOLD = 'On Hold Tasks',
  DONE = 'Done Tasks',
  RISK = 'Schedule Risk',
  CUSTOM = 'Custom Filter...',
}

export enum TimeOffType {
  Training = 'training',
  Vacation = 'vacation',
  Travel = 'travel',
  OutOfOffice = 'outOfOffice',
  PersonalDay = 'personalDay',
}

export enum TimesheetItemSelectionRule {
  DoneDate = 'DoneDate',
  Scheduled = 'Scheduled',
  TimesheetEntry = 'TimesheetEntry',
}

export enum TimesheetGroupingType {
  Upcoming = 'upcoming',
  Package = 'pkg',
  Project = 'project',
  TaskStatus = 'taskStatus',
}

export enum TimesheetReviewOptions {
  User = 'user',
  Group = 'group',
}
export enum TimesheetLockingType {
  Workspace = 'workspace',
  WorkspaceUser = 'workspaceUser',
  Group = 'group',
  Item = 'item',
}

export enum TrialStatus {
  AvailableSelfService = 'availableSelfService',
  InProgress = 'inProgress',
  AvailableContactSupport = 'availableContactSupport',
  NotSupported = 'notSupported',
}

export enum UserType {
  Member = 'member',
  Resource = 'resource',
  Placeholder = 'placeholder',
}

export enum MarketingConsentStatus {
  'Opt-In' = 'optIn',
  'Opt-Out' = 'optOut',
  Unknown = 'unknown',
}

export enum UserWorkspaceSettingFieldName {
  ArchivedGridAssignmentColumns = 'archivedGridAssignmentColumns',
  ArchivedGridProjectColumns = 'archivedGridProjectColumns',
  ArchivedGridTaskColumns = 'archivedGridTaskColumns',
  ArchivedPackageGridItemType = 'archivedPackageGridItemType',
  ArchivedPortfolioColumns = 'archivedPortfolioColumns',
  ArchivedPortfolioGridItemType = 'archivedPortfolioGridItemType',
  ArchivedProjectColumns = 'archivedProjectColumns',
  ArchivedProjectGridItemType = 'archivedProjectGridItemType',
  AutoExpandSubFolders = 'autoExpandSubFolders',
  BoardHideEmpty = 'boardHideEmpty',
  GridHideDone = 'gridHideDone',
  IncludeExpiredInvitations = 'includeExpiredInvitations',
  IterationsListColumns = 'iterationsListColumns',
  iterationsListExpandedIds = 'iterationsListExpandedIds',
  PackageWorkloadGroupIdFilter = 'packageWorkloadGroupIdFilter',
  PackageWorkloadOrganizationUserIdFilter = 'packageWorkloadOrganizationUserIdFilter',
  PendingGridAssignmentColumns = 'pendingGridAssignmentColumns',
  PendingGridProjectColumns = 'pendingGridProjectColumns',
  PendingGridTaskColumns = 'pendingGridTaskColumns',
  PendingPackageGridItemType = 'pendingPackageGridItemType',
  PendingPortfolioColumns = 'pendingPortfolioColumns',
  PendingPortfolioGridItemType = 'pendingPortfolioGridItemType',
  PendingProjectColumns = 'pendingProjectColumns',
  PendingProjectGridItemType = 'pendingProjectGridItemType',
  PortfolioWorkloadGroupIdFilter = 'portfolioWorkloadGroupIdFilter',
  PortfolioWorkloadOrganizationUserIdFilter = 'portfolioWorkloadOrganizationUserIdFilter',
  ProjectWorkloadGroupIdFilter = 'projectWorkloadGroupIdFilter',
  ProjectWorkloadOrganizationUserIdFilter = 'projectWorkloadOrganizationUserIdFilter',
  ScheduledGridAssignmentColumns = 'scheduledGridAssignmentColumns',
  ScheduledGridProjectColumns = 'scheduledGridProjectColumns',
  ScheduledGridTaskColumns = 'scheduledGridTaskColumns',
  ScheduledPackageGridItemType = 'scheduledPackageGridItemType',
  ScheduledPortfolioColumns = 'scheduledPortfolioColumns',
  ScheduledPortfolioGridItemType = 'scheduledPortfolioGridItemType',
  ScheduledProjectColumns = 'scheduledProjectColumns',
  ScheduledProjectGridItemType = 'scheduledProjectGridItemType',
  SearchIncludeDone = 'searchIncludeDone',
  TimesheetGrouping = 'timesheetGrouping',
  TimesheetReviewColumns = 'timesheetReviewColumns',
  WorkloadDayRange = 'workloadDayRange',
  WorkloadGrouping = 'workloadGrouping',
}

export enum WorkloadDayRange {
  Thirty = 30,
  Sixty = 60,
  Ninety = 90,
  OneHundredEighty = 180,
  ThreeHundredSixtyFive = 365,
  SevenHundredThirty = 730,
}

export enum WidgetGroupSize {
  Full = 'full',
  Half = 'half',
}

export enum WidgetType {
  BoardSummary = 'taskStatusDistribution',
  Changes = 'changes',
  DashboardNote = 'dashboardNote',
  Image = 'image',
  Intake = 'intake',
  LinkedNote = 'linkedNote',
  List = 'list', // Used by all of 'packages', 'projects', 'folders', 'tasks', 'users', 'picklists' list types
  MetricsTally = 'metricsTally',
  PropertiesSummary = 'properties',
  ScheduleSummary = 'schedule',
  Workload = 'workload',
  Insights = 'insights',
  TableReport = 'tableReport',
}

export const widgetTypeDisplay: ReadonlyRecord<WidgetType, string> = {
  [WidgetType.BoardSummary]: 'Task Board',
  [WidgetType.Changes]: 'Changes',
  [WidgetType.DashboardNote]: 'Dashboard Note',
  [WidgetType.Image]: 'Image',
  [WidgetType.Intake]: 'Intake',
  [WidgetType.LinkedNote]: 'Linked Note',
  [WidgetType.List]: 'List',
  [WidgetType.MetricsTally]: 'Metrics Tally',
  [WidgetType.PropertiesSummary]: 'Properties Summary',
  [WidgetType.ScheduleSummary]: 'Schedule Summary',
  [WidgetType.Workload]: 'Workload',
  [WidgetType.Insights]: 'Insights',
  [WidgetType.TableReport]: 'Report',
};

export enum WidgetOnClickViewType {
  Availability = 'availability',
  Board = 'board',
  Changes = 'changes',
  Dashboard = 'dashboard',
  DashboardNote = 'dashboard_note',
  Grid = 'grid',
  ItemPanelAssignments = 'item_panel_assignments',
  ItemPanelChanges = 'item_panel_changes',
  ItemPanelNotes = 'item_panel_notes',
  ItemPanelPlanning = 'item_panel_planning',
  ItemPanelProperties = 'item_panel_properties',
  ItemPanelSchedule = 'item_panel_schedule',
  Metrics = 'metrics',
  None = 'none',
  Profile = 'profile',
  Schedule = 'schedule',
  Timesheet = 'timesheet',
  Workload = 'workload',
}

export enum IntakeWidgetType {
  Project = 'project',
  Task = 'task',
}

export enum WidgetSize {
  Full = 'full',
  Half = 'half',
}

export enum WidgetDataErrorType {
  ASSIGNED_FILTER_ERROR = 'ASSIGNED_FILTER_ERROR',
  LOCATION_FILTER_ERROR = 'LOCATION_FILTER_ERROR',
  NO_METRICS_SELECTED_ERROR = 'NO_METRICS_SELECTED_ERROR',
  NO_PROPERTIES_SELECTED_ERROR = 'NO_PROPERTIES_SELECTED_ERROR',
  DATA_NOT_CONFIGURED = 'DATA_NOT_CONFIGURED',
  INTAKE_PARENT_MISSING = 'INTAKE_PARENT_MISSING',
  INTAKE_TEMPLATE_MISSING = 'INTAKE_TEMPLATE_MISSING',
  INTAKE_CUSTOM_FIELD_MISSING = 'INTAKE_CUSTOM_FIELD_MISSING',
  DATA_RETRIEVAL_ERROR = 'DATA_RETRIEVAL_ERROR',
}

export enum WorkloadSortOrder {
  Workload = 'workload',
  OrgUserName = 'organizationUser.username',
}

export enum WorkloadGroupingType {
  Project = 'project',
  Status = 'status',
  Upcoming = 'upcoming',
  InOrder = 'inOrder',
}

export enum WorkType {
  INDEPENDENTLY = 'independently',
  IN_ORDER = 'inOrder',
}

enum WorkTypeDisplay {
  INDEPENDENTLY = 'Schedule Independently',
  IN_ORDER = 'Schedule In Order',
}

export const WorkTypeToWorkTypeDisplay: ReadonlyRecord<WorkType, WorkTypeDisplay> = {
  [WorkType.INDEPENDENTLY]: WorkTypeDisplay.INDEPENDENTLY,
  [WorkType.IN_ORDER]: WorkTypeDisplay.IN_ORDER,
};

export enum WorkspaceUserOrderBy {
  Username = 'organizationUser.username',
}
