import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import LpLink from 'containers/shared/lp_link';
import { ContactSupportLink } from 'features/common/buttons/contact_support_link';
import { getCurrentOrganizationId } from 'features/common/current/selectors';
import LpModal from 'features/common/modals/lp_modal';
import CustomErrorMessage from 'features/errors/custom_error_message';
import { ApiError, ErrorCodes } from 'lib/api/types';
import { frontend } from 'lib/urls';
import { getOrganizationUserInCurrentOrgForUserId } from 'redux/entities/selectors/user';
import { getCommaSeparatedWorkspaceNamesForIds } from 'redux/entities/selectors/workspace';

import './invite_errors.scss';

const ManageSSOSettingsLink = () => {
  const organizationId = useSelector(getCurrentOrganizationId);
  return <LpLink to={frontend.singleSignOnManage.url({ organizationId })}>{'Manage SSO settings'}</LpLink>;
};

const ContactSupportText = () => {
  return (
    <div>
      <i>Need help? </i>
      <ContactSupportLink text="Contact Support" />
    </div>
  );
};

const InviteUnsuccessfulHeader = ({ isReconnect }: { isReconnect?: boolean }) => (
  <div className="invite-unsuccessful-header">
    <Icon size="massive" name="inbox" /> <h3>{isReconnect ? 'Reconnect' : 'Invitation'} Unsuccessful</h3>
  </div>
);

const UserDisconnectedInviteError = ({
  email,
  userId,
  organizationId,
  workspaceIds,
}: {
  email: string;
  userId: number;
  organizationId: number;
  workspaceIds: Array<number>;
}) => {
  let profileLink;

  const orgUser = useSelector((state) => getOrganizationUserInCurrentOrgForUserId(state, userId));

  if (workspaceIds.length > 1 && orgUser !== null && orgUser?.id !== undefined) {
    profileLink = (
      <LpLink
        to={frontend.organizationHubUser.url({
          organizationId,
          organizationUserId: orgUser?.id,
        })}
      >
        Profile
      </LpLink>
    );
  } else if (workspaceIds.length === 1) {
    profileLink = (
      <LpLink
        to={frontend.member.url({
          organizationId,
          userId,
          workspaceId: workspaceIds[0] ?? 0,
        })}
      >
        Profile
      </LpLink>
    );
  } else {
    profileLink = 'profile';
  }

  return (
    <>
      <InviteUnsuccessfulHeader />
      <p>
        <strong>{email}</strong>
        {' was disconnected from your Workspace. Open their '}
        {profileLink}
        {' to reconnect them.'}
      </p>

      <ContactSupportText />
    </>
  );
};

const WorkspaceMemberAlreadyGuestUserError = ({ error, email }: { error: ApiError; email: string }) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceIds: ReadonlyArray<number> = (error.meta?.workspaceIds as ReadonlyArray<number> | undefined) ?? [];
  const receiverUserId = error.meta?.receiverUserId ?? 0;
  const workspaceNames = useSelector((state) => getCommaSeparatedWorkspaceNamesForIds(state, workspaceIds));

  const isSingleWorkspace = workspaceIds.length === 1;
  const workspaceText = isSingleWorkspace ? (
    <>
      Open their{' '}
      <LpLink to={frontend.member.url({ organizationId, workspaceId: workspaceIds[0] ?? 0, userId: receiverUserId })}>
        Profile
      </LpLink>{' '}
      to upgrade them to a Member in this workspace
    </>
  ) : (
    'Open their Workspace Profile to upgrade them to a Member'
  );

  return (
    <>
      <InviteUnsuccessfulHeader />
      <p>
        <strong>{email}</strong> is already a Dashboard Guest in {workspaceNames}.
      </p>
      <p>{workspaceText}</p>

      <ContactSupportText />
    </>
  );
};

export const InviteErrors = ({ error, isReconnect }: { error: ApiError; isReconnect?: boolean }) => {
  const email = error.meta?.email ?? 'The user email you used';

  switch (error.code) {
    case ErrorCodes.InvalidInvitationExistingLpAuth: {
      return (
        <>
          <InviteUnsuccessfulHeader isReconnect={isReconnect} />
          <p>
            <strong>{email}</strong> cannot be {isReconnect ? 'reconnected' : 'invited'} to this Organization as an SSO
            user because an account already exists with this email.
          </p>
          <p>An SSO user may only belong to one Organization.</p>

          <p>
            To {isReconnect ? 'reconnect' : 'invite'} this person without SSO, an Org Admin must set the &quot;New User
            Default&quot; to LP in the <ManageSSOSettingsLink />.
          </p>

          <ContactSupportText />
        </>
      );
    }

    case ErrorCodes.InvalidInvitationExistingSso: {
      return (
        <>
          <InviteUnsuccessfulHeader isReconnect={isReconnect} />
          <p>
            <strong>{email}</strong> cannot be {isReconnect ? 'reconnected' : 'invited'} to this Organization because an
            SSO account already exists with this email.
          </p>
          <p>An SSO user may only belong to one Organization.</p>
          {isReconnect ? (
            <p>To reconnect this person, SSO must be disabled for them in their other Organizations.</p>
          ) : (
            <p>To invite this person, the user must be disconnected from all other Organizations.</p>
          )}

          <ContactSupportText />
        </>
      );
    }

    case ErrorCodes.InvalidInvitationDisconnectedUser: {
      const receiverUserId = error.meta?.receiverUserId ?? 0;
      const orgId = error.meta?.organizationId ?? 0;
      const workspaceIds = error.meta?.workspaceIds as unknown as Array<number>;
      return (
        <UserDisconnectedInviteError
          email={email}
          userId={Number(receiverUserId)}
          organizationId={Number(orgId)}
          workspaceIds={workspaceIds}
        />
      );
    }

    case ErrorCodes.WorkspaceMemberAlreadyGuestUser:
      return <WorkspaceMemberAlreadyGuestUserError email={email} error={error} />;

    default: {
      return (
        <>
          We&apos;ve encountered an unexpected error. <ContactSupportLink text="Contact support" /> for assistance
        </>
      );
    }
  }
};

export const InviteErrorModal = ({
  onClose,
  error,
  isReconnect,
}: {
  onClose: () => void;
  error: ApiError;
  isReconnect?: boolean;
}) => {
  return (
    <LpModal
      className="invite-error-modal"
      onClose={onClose}
      size="tiny"
      content={<CustomErrorMessage content={<InviteErrors error={error} isReconnect={isReconnect} />} />}
      actions={<Button content="Cancel" onClick={onClose} primary />}
    />
  );
};
